body{
    background-color: #444;
}
.title{
    color: #444;
    text-shadow: none;
    font-size: 50px;    
    
    
}
img{
    width: 500px;
    margin-bottom:20px ;
    border-radius: 5px;
}
.img2{
    width: 100%;
}
.dp5{
    font-size: 24px;
    
}
.py-5{
    background-color: #f5f5f5;
}
.teacher{
    width: 400px;
}

.table{
    font-size: 20px;
}
.content{
    font-size: 24px;
    color: gray;
}
.aaaa{
    text-align: end;
    padding-right: 90px;
    margin-top: 0;
    
}

#MyBlog{
    font-weight:bolder;
    color: black;
    position: fixed;  /*固定在網頁上不隨卷軸移動，若要隨卷軸移動用absolute*/

    top: 65%;  /*設置垂直位置*/

    right: 0;  /*設置水平位置，依所放的內容多寡需要自行手動調整*/

    background: white;  /*背景顏色*/

    padding: 10px 20px;

    border-radius: 10px;  /*圓角*/

    -moz-border-radius: 10px;

    -webkit-border-radius: 10px;    
    border: solid black 3px;
}
#MyBlog:hover{
color: white;
}

.box1{
    
    
    /* 寬度 */
    width: 1000px;
    /* 高度 */
    height: 450px;
    /* 背景顏色 */

    padding: 10px;
    
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* 居中 */
    margin: auto;
    /* 大盒子邊框 */
    border: 1px solid #cdcdcd;
}
.div_l{
    
    
    width: 600px;
    height: 400px;
    border: 1px solid #ffffff;
    /* 左浮動 */
    float: left;
    /* 大圖片左邊外邊距 */
    margin-left: 70px;
    /* 圖片超出盒子大小隱藏多餘圖片 */
    overflow: hidden;
}
.div_l img{


    width: 100%;
    height: 100%;
}
.div_r{


    width: 200px;
    height: 400px;
    /* 右浮動 */
    float: right;
    /* 豎直方向滑動條 */
    overflow-y: scroll;
}
.div_r img{

    width: 150px;
    height: 100px;
    /* 小圖片四周邊框 */
    border: 1px solid #ffffff;
    /* 取消圖片之間的間距 */
    vertical-align: top;
}
.div_r a{


    /* 將內聯元素轉變為塊元素 */
    display: block;
    /* 底部外邊距 */
    margin-bottom: 8px;
}
.my-5{
    margin-top: 0 !important;
}
.iitmlogo2{
    width: 20rem;
    height: 10rem;
  }
  .p-lg-5 {
    padding: 0!important;
}
@media (max-width: 768px) {
    .actmain
    {
        height: 35rem;
    }
.aboutcard{
    width: 20rem; 
    margin-left: 0;
    margin-top: 1rem;
    margin-left: 0;
    display: block;
}
img{
    width: 22rem;
}
.img2{
    width: 20rem;
    align-self: center;
}
.d-md-flex{
   display: block;
}
.col-md-5{
    display: block;
    width: 20rem !important;
}
.bg-body-tertiary{
    display: block;
}

.actcard{
    width: 24.5rem!important;
}
.div_l img{
    width: 21rem;

    
}
.box1{
    width: 23rem;
}
.div_l{
    margin: 0;
 
}
.div_r{
    display: flex;
    width: 350px;
}
}
