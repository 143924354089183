.contents {
   background-image: url(./img/lab.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;
    
    height:50rem;   
    background-attachment: fixed;
  
  }
  

.bg-body{
  width: 80%; 
  height: 300px; 
  border-radius: 21px 21px 0 0;
  border: 1px black solid;
}
.display-1{
  text-shadow: 2px 2px 4px #000000;
}

.box {
  color: #000000;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 0 0 #e5e5e5;
  margin: 0 0 2em 0;
  padding: 3em;
  text-align: center;
  
  width: 600px;
  height: 200px;

}

.bg-body-tertiary{
border-radius: 10px;
  background-color: white;
}
.d-md-flex{
  padding-top: 1rem;
  background-color: #f5f5f5;
}

.cimg{
  width: 100px;
}
.bg-body-tertiary{
  box-shadow: 0 2px 2px 0.5px black ;
  
}
.display-6{
  
  font-size: 18px;
  padding-left: 10px;
}
a{
  color: black;
  text-decoration: none;
}
.btn{
  border: none;
  background-color: #f5f5f5;
}
.aa{
  height: 100px;
  background-color: #f5f5f5;
}
