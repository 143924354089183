ul { /* 取消ul預設的內縮及樣式 */
    margin: 0;
    padding: 0;
    list-style: none;
    margin-right: 50px;
}

ul.drop-down-menu {
    display: inline-block;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 13px;
}

ul.drop-down-menu li {
    position: relative;
    white-space: nowrap;
}

ul.drop-down-menu > li:last-child {
    border-right: none;
}

ul.drop-down-menu > li {
    float: left; /* 只有第一層是靠左對齊*/
}

 ul.drop-down-menu a {
    
    color: white;
    
    display: block;
    padding: 0 30px;
    text-decoration: none;
    line-height: 50px;

}
ul.drop-down-menu a:hover { /* 滑鼠滑入按鈕變色*/
    background-color: #ef5c28;
    color: #fff;
}
ul.drop-down-menu li:hover > a { /* 滑鼠移入次選單上層按鈕保持變色*/
    background-color: #ef5c28;
    color: #fff;
}

ul.drop-down-menu ul {
    border: #ccc 1px solid;
    position: absolute;
    z-index: 99;
    left: -1px;
    top: 100%;
   min-width: 180px;
}

ul.drop-down-menu ul li {
    border-bottom: #ccc 1px solid;
}

ul.drop-down-menu ul li:last-child {
    border-bottom: none;
}

ul.drop-down-menu ul ul { /*第三層以後的選單出現位置與第二層不同*/
    z-index: 999;
    top: 10px;
    left: 90%;
}
ul.drop-down-menu ul { /*隱藏次選單*/
    display: none;
}

ul.drop-down-menu li:hover > ul { /* 滑鼠滑入展開次選單*/
    display: block;
    background-color: gray;
    
}

.nav{
background-color: none  ;
padding: 0;



}

.fs-2{
    font-size: 72px;
    color: white;
}

a:hover{
    color: gray;
}
.nav-item{
    font-size: 24px;
    text-align: left;
}
.iitmlogo{
    width: 13rem ;
    height: 6rem ;
}